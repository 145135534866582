import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PTZTourTable from 'components/Web_User_Interface/720p_Series/System/PTZ_Tour/ptzTourTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // PTZ Tour",
  "path": "/Web_User_Interface/720p_Series/System/PTZ_Tour/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "Deactivate your camera´s calibration run at startup (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.",
  "image": "./WebUI_720p_SearchThumb_System_PTZ_Tour.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_System_PTZ_Tour.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='System Menu // PTZ Tour' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Deactivate your camera´s calibration run at startup (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.' image='/images/Search/WebUI_720p_SearchThumb_System_PTZ_Tour.png' twitter='/images/Search/WebUI_720p_SearchThumb_System_PTZ_Tour.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/System/PTZ_Tour/' locationFR='/fr/Web_User_Interface/720p_Series/System/PTZ_Tour/' crumbLabel="PTZ Tour" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <h3 {...{
      "id": "ptz-tour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ptz-tour",
        "aria-label": "ptz tour permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ Tour`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/6bbf7/WebUI-System_PTZ-Tour.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAADIklEQVQ4y32T327iRhSHeayqfY5229fovsT2OhLbCAzC/NtslbACHAhmgZBAiEkIAWMbPGNjM2bAYxuHgLhMZTsp2dWm38WRNdLn3zk6M6F8ofDrb+9++vmXd7//QVFULBZLJpNUlIpGI1QsRtN0PB5P0PTfPvF4PJ1OZ7OfIh7REMMw4fDHDx/+oiiq17vt9/s8z49Go6AKgiD4lef54XAIAMjn8+/f/9loNOr1eiibzWYzmVwuV6/VdF2XRFFRlLWP6z64Lzw8eN/r9RpjHIlEut1uv98PpVKpg4ODTCbDsqymabquQ6h4qOpisTBNkxBCTHPpgzE2DKNSqXAcd3NzE0rQiXA4/PnzP+VyeTabzXR9Op2qijKbzQghGOO5YSyXy5WP4zi2bReLxWe5Wq2WyuVOp9PtdhFCqqKqiioIAkJI07SxhzTVtMAMYBjmWbYsslq5u93u8fERY6wqiqqooigahoEQAgDIsjzTZ84P5amqSpJkEctZORhj6E3snSDkyRDCyWTyZvISLzDGT09P2+0Wz+cAAAihNB4bPhBCr+3p9Mdy67J1ksudnJx0Oh1CCJBlAMBoNApmnozHk/F4On0judls0okETdOtVosQIk8mXrLXtgcEQJLE/5KDupfz+fzx8XHuy5davWEgFGxL9Ze8WCx0f3OGYTirlf3CXo7HY4eHh4VCgWEYhNB6vX4dslqtXNcNqqZpLMtKklQul6+vrz05mUxGqWgqlWaYU4SQ67qWZQWX4TtM0wQAYIxPT0+fk88b55cXl7VavV6vI4QkUVKgIk9kIAPbth3HsV6wbTv49b5tfuC9H57ne71b5M9MiEUIsSyLmKbjONvN5vEV38jtdrtUKp2dnV1z3Hw+hxCKoiCIgiiKo9Ho/v5elmXlBQihYRj7ti8uLqrVKsuyV1dXhmGIojgYDIY+PD9st1ocxw2Hw4FPv99XFKVUKj3LHMdVKmytVuP85ODdvn7G3518s+erq/bXr9VGo9HpdCzL2u12m81m+7/sk3u922bzvNVu393dFYvFTCbz6W2Ojo7S6TRN071er9vt/gsxqA652PoPSQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c15fd9851daadfbfce2665b78e3dd6e/e4706/WebUI-System_PTZ-Tour.avif 230w", "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/d1af7/WebUI-System_PTZ-Tour.avif 460w", "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/04ac8/WebUI-System_PTZ-Tour.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3c15fd9851daadfbfce2665b78e3dd6e/a0b58/WebUI-System_PTZ-Tour.webp 230w", "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/bc10c/WebUI-System_PTZ-Tour.webp 460w", "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/d8378/WebUI-System_PTZ-Tour.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c15fd9851daadfbfce2665b78e3dd6e/81c8e/WebUI-System_PTZ-Tour.png 230w", "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/08a84/WebUI-System_PTZ-Tour.png 460w", "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/6bbf7/WebUI-System_PTZ-Tour.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3c15fd9851daadfbfce2665b78e3dd6e/6bbf7/WebUI-System_PTZ-Tour.png",
              "alt": "Web User Interface - 720p Series - System PTZ Tour",
              "title": "Web User Interface - 720p Series - System PTZ Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTourTable mdxType="PTZTourTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      